body {
  margin: 0;
  font-family: "Nunito Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-weight: 100;
}

.stepIcon {
  margin-top: 0.2%;
  margin-right: 20px;
  font-size: 20pt;
  color: rgb(36, 136, 161);
}

.closedIcon {
  font-size: 25pt;
  margin-left: 10px;
}

.MuiPickersDay-daySelected {
  background-color: #40B3DB;
}

.MuiPickersDay-current {
  color: #40B3DB;
}

.MuiTabs-indicator {
  background-color: #40B3DB;
}

.MuiPickersCalendarHeader-switchHeader {
  height: 54px;
  display: flex;
  margin-top: -11px;
  align-items: center;
  border-radius: 4px 4px 0 0;
  margin-bottom: 0px;
  justify-content: space-between;
  background-color: #E1E5E5;
}

.MuiPickersCalendarHeader-daysHeader {
  margin-top: 10px;
}

.MuiPickersCalendarHeader-switchHeader > button {
  background-color: #E1E5E5;
}

.MuiTabs-root {
  height: 63px;
}

.MuiTabs-scroller.MuiTabs-fixed {
  height: 63px;
}

.MuiTabs-flexContainer.MuiTabs-centered {
  height: 63px;
  align-items: center;
}

.PrivateTabIndicator-root-1 {
  height: 4px;
}

.closedIcon:hover,
.editIcon:hover {
  cursor: pointer;
}

li.MuiMenuItem-root.Mui-selected {
  background-color: white!important;
}
li.MuiMenuItem-root.Mui-selected:after{
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  float: left;
  margin: -4px 0px 0px 5px;
}
li.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  background-color: #CFEDF1;
}

#menu- .MuiMenu-paper {
  margin-top: 50px;
}

.lockModel{
  z-index: -1;
  display:none;
}

@media screen and (min-width: 320px) and (max-width: 930px) and (orientation: portrait) {
  .lockModel{
    z-index:2147483628!important;
    background-size: 100% 100%;
    height:100%;
    position:fixed;
    width: 100%;
    display:block;
  }
  body {
    overflow: hidden;
    position: fixed;
    pointer-events:none;
  }
}
