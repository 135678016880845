.sc-message {
  width: 370px;
  margin: auto;
  padding-bottom: 10px;
  display: flex;
}

.sc-message--content {
  width: 100%;
  display: flex;
}

.sc-message--avatar {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 30px;
  height: 30px;
  align-self: center;
  margin-top: -80px;
}
.sc-message--avatar.received {
  width: 30px !important;
  height: 30px !important;
  margin-top: -30px;
}

.tickIcon {
  width: 18px !important;
  height: 18px !important;
  text-align: right !important;
}

.tickIcon:hover {
  cursor: pointer;
}

.resolve {
  color: #1c7b97;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
  margin: 0;
  margin-top: -26px;
}

.resolved {
  color: #1c7b97;
  font-family: "Nunito Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
  margin: 0;
  margin-top: -26px;
}

.resolve:hover {
  cursor: pointer;
  color: #399ab8;
}

.resolveWrapper {
  margin-top: -22px;
}

.sc-message--text {
  padding: 17px 20px;
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 6px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  -webkit-font-smoothing: subpixel-antialiased;
  word-wrap: break-word;
  width: 85%;
}

.sc-message-text.received {
  padding-left: 0;
  margin-left: 20px;
  width: 90% !important;
}

.sc-message--content.sent .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
  max-width: calc(100% - 90px);
  word-wrap: break-word;
}

.sc-message--content.received .sc-message--text {
  color: #263238;
  background-color: #f4f7f9;
}

.sc-message-name {
  color: #213d58;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 26px;
  margin: 0px;
}

.sc-message-name.fileAuthor {
  margin-left: 15px;
}

.sc-message-subheading-text {
  color: #6c7d80;
  font-family: "Nunito Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0px;
  display: inline-block;
}
.date {
  float: right;
}
.file {
  margin: 0;
  padding-left: 20px;
  padding-right: 5px;
  color: #6c7d80;
  font-family: "Nunito Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.sc-message-link {
  color: #1c7b97;
  font-family: "Nunito Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.sc-message-link:hover {
  cursor: pointer;
}

.sc-message-comment {
  color: #0a0d0d;
  font-family: "Nunito Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0px;
}

.sc-message--emoji {
  font-size: 40px;
}

.sc-message--file {
  background: white;
  border: solid 1px #cccdd1;
  padding: 15px 20px;
  border-radius: 5px;
  display: flex;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  text-decoration: none;
  margin-left: 15px;
}

.sc-message--file p {
  margin: 0px 0px 0px 10px;
  color: rgba(86, 88, 103, 0.6);
}

.sc-message--file .sc-user-input--file-icon:hover path {
  fill: rgba(86, 88, 103, 0.3);
}

@media (max-width: 450px) {
  .sc-message {
    width: 80%;
  }
}

.icon-initials {
  z-index: 5;
  margin-left: 0px;
}
