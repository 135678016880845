.sc-header {
  background: #40b3db;
  min-height: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #0a0d0d;
  font-family: "Nunito Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.sc-header--img {
  border-radius: 50%;
  align-self: center;
  padding: 10px;
}

.sc-header--team-name {
  align-self: center;
  padding-left: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
  flex: 1;
  user-select: none;
  border-radius: 5px;
}

.sc-header--close-button {
  width: 40px;
  align-self: center;
  height: 40px;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
}

.sc-header--close-button:hover {
  background: #3bacd1;
}

.sc-header--close-button img {
  width: 100%;
  height: 100%;
  padding: 13px;
  box-sizing: border-box;
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0px;
  }
}
