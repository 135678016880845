.sc-user-input {
  min-height: 130px;
  margin: 0px;
  position: relative;
  bottom: 0;
  /* display: flex; */
  background-color: #f4f7f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  border-top: 2px solid #9edae2;
}

.sc-user-input--text {
  width: 90%;
  resize: none;
  border: none;
  outline: none;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #565867;
  -webkit-font-smoothing: antialiased;
  /* max-height: 200px; */
  overflow: scroll;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 60px;
  margin-top: 18px;
}

.sc-user-input--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: #8b9799;
  font-family: "Nunito Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
}

.sc-user-input--buttons {
  /* width: 100px; */
  position: absolute;
  right: 20px;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sc-user-input--button:first-of-type {
  width: 40px;
  padding-right: 10px;
}

.sc-user-input--button {
  width: 30px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sc-user-input--button button {
  cursor: pointer;
}

.sc-user-input--buttons input[type="file"] {
  display: none;
}

.sc-user-input--picker-wrapper {
  display: flex;
  flex-direction: column;
}

.sc-user-input.active {
  box-shadow: none;
  background-color: white;
  box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
}

.sc-user-input--file-icon,
.sc-user-input--send-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  align-self: center;
  outline: none;
}

.sc-user-input--file-icon path,
.sc-user-input--send-icon path {
  fill: rgba(86, 88, 103, 1);
}

.sc-user-input--file-icon:hover path,
.sc-user-input--send-icon:hover path {
  fill: #40b3db;
}

.sc-user-input--emoji-icon-wrapper,
.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  background: none;
  border: none;
  padding: 2px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  flex-direction: row;
}

.sc-user-input--emoji-icon-wrapper:focus {
  outline: none;
}

.sc-user-input--emoji-icon {
  height: 18px;
  cursor: pointer;
  align-self: center;
}

.sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon circle {
  fill: rgba(86, 88, 103, 0.3);
}

.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon circle,
.sc-user-input--emoji-icon.active path,
.sc-user-input--emoji-icon.active circle,
.sc-user-input--emoji-icon:hover path,
.sc-user-input--emoji-icon:hover circle {
  fill: rgba(86, 88, 103, 1);
}

.input-icon {
  margin-left: 15px;
  margin-top: -10px;
}
